
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/localStorageService';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem: any;
  public isOpenMobile: boolean = false
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  idTenant: string | null = null;
  isAdmin: boolean;
  initialName:string | null = null;;
  constructor(
    public navServices: NavService,
    private cookie: CookieService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    public localStorageService: LocalStorageService,
    private translate: TranslateService,
    ) {
  }


  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang: any) {    
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items: any = [];
    term = term.toLowerCase();
    this.items.filter((menuItems: any) => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter((subItems: any) => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter((suSubItems: any) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })

        return
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
      return
    });
    return
  }

  checkSearchResultEmpty(items: any) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    this.idTenant = this.localStorageService.getIdTenant();
    this.isAdmin = this.localStorageService.getIsAdmin() === 'true' ? true : false;
    this.initialName = this.localStorageService.getUsersName();
    if(this.initialName) this.initialName = this.initialName[1].toUpperCase();


  }

  SignOut() {
    this.localStorageService.clear();
    //this.cookie.delete("token", "/");
    this.router.navigate(['/login']);
    //localStorage.clear();
  }

  async detailEnterprise(id: string | null) {
    if (id == null) {
      this.router.navigate(['inicio/configuracion/empresas/information-empresa/' + "null"]);
    } else {
      this.router.navigate(['inicio/configuracion/empresas/information-empresa/' + id]);
    }
  }

}
