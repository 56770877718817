import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardLogin {
    constructor(
        private cookie: CookieService,
        private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        let isLoggedIn = this.cookie.check('token');
        if (isLoggedIn == true) {
            this.router.navigate(['/inicio']);
            return false;
        }
        return true;
    }
}