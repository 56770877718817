import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { CustomizerService } from 'src/app/core/shared/services/customizer.service';
import { NavService } from 'src/app/core/shared/services/nav.service';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn , {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class DashboardComponent implements OnInit {


  public right_side_bar: boolean;

  constructor(
    public navServices: NavService,
    public customizer: CustomizerService) { }


  ngAfterViewInit() {

  }

  // @HostListener('document:click', ['$event'])
  // clickedOutside(event: any) {
  //   //click outside Area perform following action
  //   document.getElementById('outer-container')!.onclick = function (e) {
  //     e.stopPropagation()
  //     if (e.target != document.getElementById('search-outer')) {
  //       document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  //     }
  //     if (e.target != document.getElementById('outer-container')) {
  //       document.getElementById("canvas-bookmark")!.classList.remove("offcanvas-bookmark");
  //     }
  //     if (e.target != document.getElementById('inner-customizer')) {
  //       document.getElementsByClassName("customizer-links")[0].classList.remove("open")
  //       document.getElementsByClassName("customizer-contain")[0].classList.remove("open")
  //     }
  //   }
  // }

  public getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event: any) {
    this.right_side_bar = $event
  }

  ngOnInit() { }
}
