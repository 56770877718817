import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private cookie: CookieService) { }

  setMenu(menu: string) {
    localStorage.setItem('menu', menu);
  }

  getMenu() {
    return localStorage.getItem("menu");
  }

  setUsersName(usersName: string) {
    localStorage.setItem('usersName', (JSON.stringify(usersName)));
  }

  getUsersName() {
    return localStorage.getItem("usersName");
  }

  setToken(token: string) {
    this.cookie.set('token', token, 1, "/");
  }

  setApplicationRolName(applicationRolName: string) {
    localStorage.setItem('applicationRolName', applicationRolName);
  }

  getIdTenant() {
    return localStorage.getItem("idTenant");
  }

  setIdTenant(idTenant: string) {
    localStorage.setItem('idTenant', idTenant);
  }

  getApplicationRolName() {
    return localStorage.getItem("applicationRolName");
  }

  setNitTenant(nitTenant: string) {
    localStorage.setItem('nitTenant', nitTenant);
  }

  getNitTenant() {
    return localStorage.getItem("nitTenant");
  }

  setImageLogo(imageLogo: string) {
    localStorage.setItem('imageLogo', imageLogo);
  }
  removeImageLogo() {
    localStorage.removeItem('imageLogo');    
  }

  getImageLogo() {
    return localStorage.getItem("imageLogo");
  }

  setIsAdmin(isAdmin: boolean) {
    localStorage.setItem('isAdmin', String(isAdmin));
  }

  getIsAdmin() {
    return localStorage.getItem("isAdmin");
  }

  setIsFabric(isFabric: boolean) {
    localStorage.setItem('isFabric', String(isFabric));
  }

  getIsFabric() {
    return localStorage.getItem("isFabric");
  }

  clear() {
    this.cookie.delete("token", "/");
    localStorage.clear();
  }

  getBaseURL() {
    return localStorage.getItem("BaseURL");
  }

  setBaseURL(BaseURL: string) {
    localStorage.setItem('BaseURL', BaseURL);
  }

  getUserProfileId() {
    return localStorage.getItem("userProfileId");
  }

  setUserProfileId(userProfileId: string) {
    localStorage.setItem('userProfileId', userProfileId);
  }

  getRolId() {
    return localStorage.getItem("rolId");
  }

  setRolId(rolId: string) {
    localStorage.setItem('rolId', rolId);
  }

}