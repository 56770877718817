import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { DecimalPipe } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import "hammerjs";
import "mousetrap";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from "ngx-spinner";
import { SpinnerStandComponent } from "./core/shared/spinner/spinner.component";
import { AuthInterceptorService } from "./core/shared/interceptors/authInterceptorService";


@NgModule({
  declarations: [
    AppComponent,    
    
  ],
  imports: [        
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    SpinnerStandComponent,
  ],
  providers: [
    CookieService,
    DecimalPipe,    
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}