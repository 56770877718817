<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{ open: navServices.collapseSidebar }" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left col-auto p-0 d-lg-none">
      <div class="logo-wrapper">
        <a href="javascript:void(0)">
          <img src="assets/img/ImgOfima.png" alt="" />
        </a>
      </div>
    </div>
    <div class="mobile-sidebar col-auto p-0">
      <div class="media-body text-start switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <i class="fz-25 fa-solid fa-bars"></i>
            <!-- <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons> -->
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{ open: openNav }">
        <li>
          <!-- <form class="form-inline search-form">
            <div class="form-group mb-0">
              <input class="form-control-plaintext" [class.open]="isOpenMobile" [(ngModel)]="text"
                [ngModelOptions]="{ standalone: true }" (keyup)="searchTerm(text)" type="text" autocomplete="off"
                placeholder="Search.." />
              <span class="d-sm-none mobile-search">                
              </span>
              <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
                <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">
                  <div class="ProfileCard-avatar">                    
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
                <div class="tt-dataset tt-dataset-0">
                  <div class="EmptyMessage">Opps!! There are no result found.</div>
                </div>
              </div>
            </div>
          </form> -->
        </li>        
        <li class="onhover-dropdown">
          <a class="txt-dark" href="javascript:void(0)">
            <h6>ES</h6>
          </a>
          <ul class="language-dropdown onhover-show-div p-20 dropdownlistLenguage">
            <li>
              <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i
                  class="flag-icon flag-icon-um"></i> Español</a>
            </li>
            <li>
              <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                  class="flag-icon flag-icon-is"></i> English</a>
            </li>
          </ul>
        </li>
        <!-- <li class="onhover-dropdown">          
          <span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notification <span class="badge rounded-pill badge-primary pull-right">3</span></li>
            <li>
              <div class="media">
                <div class="flex-grow-1">
                  <h6 class="mt-0">
                    <span>                      
                    </span>Your order ready for Ship..!<small class="pull-right">9:00 AM</small>
                  </h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="flex-grow-1">
                  <h6 class="mt-0 txt-success">
                    <span>                      
                    </span>Download Complete<small class="pull-right">2:30
                      PM</small>
                  </h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="flex-grow-1">
                  <h6 class="mt-0 txt-danger">
                    <span>                      
                    </span>250 MB trush files<small class="pull-right">5:00 PM</small>
                  </h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>
          </ul>
        </li> -->
        <!-- <li>
          <a>
            <p (click)="right_side_bar()">qweqwe</p>
            <span class="dot"></span>
          </a>
        </li> -->
        <li class="onhover-dropdown">
          <!-- <div class="media align-items-center">
            <i class="fa-solid fa-user-tie fz-25"></i>
          </div> -->
          <div class="avatar avatar-sm me-2 text-center">
            <span class="avatar-initial rounded-circle" style="font-size: 1.5em; color: white;">{{initialName}}</span>
           </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li *ngIf="isAdmin && idTenant!='undefined'">
              <a href="javascript:void(0)" (click)="detailEnterprise(idTenant)">
                Mi empresa </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="SignOut()">
                Cerrar sesión </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <i class="fz-25 fa-solid fa-ellipsis"></i>
        <!-- <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons> -->
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->