import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Alerts } from "../Alerts/Errors";
import { of } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { LocalStorageService } from "../services/localStorageService";

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private _cookie: CookieService,
        private _ruta: Router,
        private spinner: NgxSpinnerService,
        private localStorageService: LocalStorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let isLoggedIn = this._cookie.check('token');
        if (isLoggedIn == false) {
            if (request.context.get(BYPASS_LOG) == true) return next.handle(request)
        } else {
            const jwtHelper: JwtHelperService = new JwtHelperService();
            if (jwtHelper.isTokenExpired(this._cookie.get('token'))) {
                this.localStorageService.clear();
                this._ruta.navigate(['login']);
                Alerts.warning("Error", "La sesión se venció", "Aceptar");
                this.spinner.hide();
                setTimeout(() => {
					window.location.reload();
				}, 2000);
                return of();
            }
            var token = this._cookie.get("token");
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request);
    }
}
