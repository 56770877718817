import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/shared/guards/AuthGuard';
import { AuthGuardLogin } from './core/shared/guards/AuthGuardLogin';
import { DashboardComponent } from './admin/appClients/dashBoard/dashboard.component';


export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/core/shared/Login/login.module').then(a => a.LoginModule),
    canActivate: [AuthGuardLogin]
  },
  {
    path: 'inicio',
    component: DashboardComponent,
    loadChildren: () => import(`./admin/appClients.module`).then(m => m.AppClientesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recuperar',
    loadChildren: () => import('../app/core/shared/recoveryPassword/recovery-password.module').then(a => a.RecoveryPasswordModule),
    //canActivate: [AuthGuardLogin]
  },
  {
    path: '**',
    redirectTo: 'login'
  }

];

@NgModule({
  declarations: [

  ],
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
