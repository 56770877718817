import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}
	MENUITEMS: Menu[] = [];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	setMenu() {
		var menu = localStorage.getItem('menu');
		if (menu != null) {
			var menuParse = JSON.parse(menu);
			if(menuParse?.length > 0){
				menuParse[0]!.children[0]!.active = true;
				this.MENUITEMS = menuParse;				
				this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
			}			
		}
	}
}
