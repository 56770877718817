<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright m-auto t-center">      
      <img style="width: 10%; height: 30%;" src="assets/img/ImgOfima.png" alt="">
      <p class="mb-0">Copyright 2023 © Ofima todos los derechos reservados.</p>
      <p class="mb-0">{{ 'Versión:' + versionApp  }} </p>
    </div>    
  </div>
</div>
